import React from "react";
import NavbarFullMenu from "components/Navbar-full-menu/navbar.full-menu";
import ShowcasesFullScreen from "components/Showcases-full-screen/showcases-full-screen";
import LightTheme from "layouts/Light";
import Navbar from "components/Navbar/navbar";

const ShowcaseLight = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  return (
    <LightTheme>
      {/* <NavbarFullMenu theme="light" /> */}
      <Navbar nr={navbarRef} lr={logoRef} />
      <ShowcasesFullScreen />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Mediarc</title>
    </>
  )
}

export default ShowcaseLight;
